import { config } from '../_constants';
import {base64EncodeUnicode} from '../_helpers';

export function prepararUrl(p_type,p_route,p_option,p_others_param) {
    let v_route = base64EncodeUnicode(p_route);
    let v_option = base64EncodeUnicode(p_option);
    var v_url = config.backendUrl;
    v_url = `${v_url}?r=${v_route}&o=${v_option}`;

    if(Array.isArray(p_others_param) && p_others_param.length>0) {
        p_others_param.forEach(element => {
            v_url += `&${element.name}=${base64EncodeUnicode(element.value)}`;
        });
    }

    return v_url;
}