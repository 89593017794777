import React,{useEffect, useState} from 'react';

import Home from './_components/home';

/*******PARAMETROS DE URL*******/
var queryString = window.location.search;
var urlParams = new URLSearchParams(queryString);

var g_cdv_value = urlParams.get('cdv'); //Código de Verificación
/*******FIN PARAMETROS DE URL*******/
function App() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
        <div className="container-fluid">
          <a className="navbar-brand" href="https://mi.cftestataltarapaca.cl/"><img src="img/logo3.png" style={{width: "120px"}} className="card-img-top" alt="..." /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="https://mi.cftestataltarapaca.cl/">Inicio</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <main role="main" className="container">
        {<Home cdv={(g_cdv_value==null)? '':g_cdv_value}/>}
      </main>
      <footer className="footer">
        <div className="container">
          <a className="text-muted" href="https://mi.cftestataltarapaca.cl/politica.php" target="_self" style={{textDecoration: "none"}}>
            Políticas de Privacidad
          </a>
        </div>
      </footer>
    </>
  );
}

export default App;
