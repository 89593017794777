import React,{useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
//import $ from 'jquery';

//import { ToastContainer, Slide, toast} from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

import {base64DecodeUnicode, base64EncodeUnicode, prepararUrl, toastOption} from '../_helpers';
import { config } from '../_constants';

var root_modal=null;

export default function Home(props){

    const [codigo,setCodigo] = useState(props.cdv);
    const [respuesta,setRespuesta] = useState({fileid: '', status: '', message: ''});
    const [buscando,setBuscando] = useState(false);

    function _handleChangeInput(e){
        let v_value = e.target.value;
        let v_name = e.target.name;
        
        //console.log("e",e);
        //console.log("key",e.keyCode);
        //console.log("code",e.code);
        //if ((e.keyCode != 32) && (e.keyCode < 65) || (e.keyCode > 90) && (e.keyCode < 97) || (e.keyCode > 122))
        //    return;

        setCodigo(v_value);
    }

    function estaVacio(valor) {
        valor = valor === undefined ? "" : valor;
        valor = valor === null ? "" : valor;
        valor = (valor+'').replace("&nbsp;", "");

        if (!valor || 0 === valor.trim().length) {
            return true;
        }
        else {
            return false;
        }
    }    

    async function _handleClickValidarDocumento(){

        if(estaVacio(codigo)){
            return false;
        }

        try {
            const requestOptions = { 
                method: 'POST',
                body:  JSON.stringify({cdv: codigo})
            };
      
            let v_url = prepararUrl('backend','base',"1");
            setBuscando(true);
            const response = await window.fetch(v_url, requestOptions);
            const resp = await response.json();
            
            if(response.ok){
                setBuscando(false);
                setRespuesta((prevState) => ({
                    ...prevState,
                    status: 'success',
                    fileid: resp.data.fileid,
                    message: 'El documento es válido.'
                }))
            }
            else{
              if(response.status===400){
                setRespuesta((prevState) => ({
                    ...prevState,
                    status: 'danger',
                    fileid: '',
                    message: resp.errores[0]
                }))
              }
              setBuscando(false);
            }      
        }
        catch(error){
            
            setRespuesta((prevState) => ({
                ...prevState,
                status: 'danger',
                fileid: '',
                message: 'Ocurrió un error inesperado al validar el documento. Por favor, intente nuevamente mas tarde.'
            }))
            setBuscando(false);
        };
    }

    function _handleClickDescargarFile(){
        if(estaVacio(respuesta.fileid)){
            return false;
        }

        let v_url = prepararUrl('backend',"base","2",[{name: 'var1',value: respuesta.fileid}]);
        window.open(v_url);
    }

    useEffect(() => {
        
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return(
        <section>
            <div style={{width: "18rem"}} className="card border border-info shadow">        
                <div className="card-header">
                <img src="img/logo1.png" className="card-img-top" alt="..." />
                </div>
                <div className="card-body">
                    <h5 className="card-title text-center">Validador de Documentos</h5><br />
                    <form>
                        <div className="mb-3">
                        <label htmlFor="codigo" className="form-label">Código de Verificación</label>
                        <input 
                            type="text" 
                            className="form-control text-center" 
                            id="codigo" 
                            name="codigo" 
                            aria-describedby="codigoHelp" 
                            onChange={_handleChangeInput}
                            value={codigo}
                        />
                        <div id="codigoHelp" className="form-text">
                            Ingrese el código de verificación que muestra el documento que desea validar.
                        </div>
                        </div>
                    </form>
                    {
                    (respuesta.status!='')?
                    <div className={'alert alert-'+respuesta.status}>                        
                        {respuesta.message}
                    </div>
                    :
                    ""
                    }
                    {
                    (respuesta.status==='success')?
                    <button 
                        className="btn btn-success"
                        title="Ver Documento" 
                        onClick={_handleClickDescargarFile}
                    >                 
                        Ver Documento
                    </button>
                    :
                    ""
                    }

                    <button 
                        title="Validar Documento" 
                        className="btn btn-primary float-end"
                        onClick={_handleClickValidarDocumento}
                    >                 
                        Validar
                    </button>
                </div>
            </div>
        </section>
    )
}